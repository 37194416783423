<template>
  <section id="dashboard-ecommerce">
    
    <!--   <b-row class="match-height">
      <b-col md="12">
        <SynchronizedChart />
      </b-col>
    </b-row> -->

    <b-row class="match-height">
      <b-col md="3" v-for="n in header" v-bind:key="n.id">
        <b-card no-body>
          <b-card-header>
            
            <div>
              <b-card-title class="mb-1">
                {{n.Title}}
                <feather-icon size="32" :icon="n.Icon" class="mr-50"/>
              </b-card-title>
              
            </div>
          </b-card-header>
            <!--/ title and subtitle -->
          <b-card-body style="font-size: 40px; text-align:center">
            {{n.Number}}
            
          </b-card-body>
        </b-card>
      </b-col>
            <b-col md="1">
      </b-col>
    </b-row>


    <b-row class="match-height">
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            
            <div>
              <b-card-title class="mb-1">
                Recent Order Details (Latest 5 Orders)
              </b-card-title>
              
            </div>
          </b-card-header>
            <!--/ title and subtitle -->
          <b-card-body>
  <div>
    <b-table sticky-header striped hover :items="items"></b-table>
  </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <!-- <b-row class="match-height">
      <b-col md="6">
        <ZoommableGraph />
      </b-col>
    </b-row> -->
  </section>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BDropdown, BDropdownItem,BCardSubTitle, BTable } from 'bootstrap-vue'

//import { getUserData } from '@/auth/utils'
import { GET_API,POST_API } from "../../store/actions.type"
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import flatPickr from 'vue-flatpickr-component'
import moment from "moment-timezone";
import ChartjsComponentBarChart from './charts-components/ChartjsComponentBarChart.vue'
import Bus from "../../event-bus";
import CustomFormGraph from './customFormGraph.vue';
import ZoommableGraph from './zoommableChart.vue';
import SynchronizedChart from './SynchronizedChart.vue';

export default {
  components: {
    BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,VueApexCharts,BDropdown, BDropdownItem,flatPickr,BCardSubTitle,ChartjsComponentBarChart,CustomFormGraph,ZoommableGraph, SynchronizedChart, BTable

  },
  data() {
    return {
      header: [ { Title: 'New Deliver Orders', Icon: 'AlertOctagonIcon', Number: 0 } ,
      { Title: 'New Return Orders', Icon: 'AlertTriangleIcon', Number: 0 } , 
      { Title: 'New Exchange Orders', Icon: 'Minimize2Icon', Number: 0 } , 
      { Title: 'Daily Completed Orders', Icon: 'CheckIcon', Number: 0 } , 
      ],

      
      items: [],

      items2: [],
    }
  },
  methods:{
    getCustomerDeliverCount(){

      return this.$store.dispatch(POST_API, {
           data:{
             company_name: this.$store.getters.currentUser.company_name
           },
           api: '/api/customer-get-deliver-return-count'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.header[0].Number = data.total;
            }
        });
    },
    getCustomerReturnCount(){

      return this.$store.dispatch(POST_API, {
           data:{
             company_name: this.$store.getters.currentUser.company_name
           },
           api: '/api/customer-get-return-count'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.header[1].Number = data.total;
            }
        });
    },
    getCustomerExchangeCount(){

      return this.$store.dispatch(POST_API, {
           data:{
             company_name: this.$store.getters.currentUser.company_name
           },
           api: '/api/customer-get-exchange-count'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.header[2].Number = data.total;
            }
        });
    },
      dailyCompletedOrdersCount(){

      return this.$store.dispatch(POST_API, {
           data:{
             company_name: this.$store.getters.currentUser.company_name
           },
           api: '/api/daily-completed-orders-count'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.header[3].Number = data.total;
            }
        });
    },
    /*getCustomerInProgressCount(){

      return this.$store.dispatch(POST_API, {
           data:{
             company_name: this.$store.getters.currentUser.company_name
           },
           api: '/api/customer-get-in-progress-count'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.header[1].Number = data.total;
            }
        });
    },*/
    /*getCustomerMonthOrderCount(){

      return this.$store.dispatch(POST_API, {
           data:{
             company_name: this.$store.getters.currentUser.company_name
           },
           api: '/api/total-customer-month-order-count'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.header[2].Number = data.total;
            }
        });
    },*/
   /*getCustomerCompletedOrderCount(){

      return this.$store.dispatch(POST_API, {
           data:{
             company_name: this.$store.getters.currentUser.company_name
           },
           api: '/api/total-customer-order-count'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.header[3].Number = data.total;
            }
        });
    },*/
     recentCustomerOrderDetails(){

      return this.$store.dispatch(POST_API, {
           data:{
             company_name: this.$store.getters.currentUser.company_name
           },
           api: '/api/recent-customer-order-details'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;

                this.items = data.docs;
            }
        });
    },
  },
  mounted() {
    if(this.$store.getters.currentUser.account_type == 'Admin')
    {
      this.$router.push({ name: this.setDefaultPage('client-dashboard')})
    }
    this.getCustomerDeliverCount();
    this.getCustomerReturnCount();
    this.getCustomerExchangeCount();
    this.dailyCompletedOrdersCount();
    //this.getCustomerMonthOrderCount();
    //this.getCustomerCompletedOrderCount();
    this.recentCustomerOrderDetails();
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
